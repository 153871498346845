import React from "react"
import {useTranslation} from "react-i18next"

import './smart-city.component.less';

import smartCityMain from "../../assets/img/smart-city/smart-city-main.jpg";
import smartCitySmall from "../../assets/img/smart-city/smart-city-small.jpg";
import {useI18next} from "gatsby-plugin-react-i18next";
import Seo from "../seo";

const SmartCityComponent = () => {
    const {i18n, t} = useTranslation();
    const { originalPath } = useI18next();

    return (
        <div>
            <Seo description={t("metaDescriptionSmartCity")} lang={i18n.language} title={t("titleSmartCity")} link={"https://cityair.io/"+ i18n.language + originalPath} />
            <div className="smart-city">
                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-padding-bottom main-block padding-top-50">
                    <div className="main-block__text">
                        <div className="main-block__title">
                            <span><span dangerouslySetInnerHTML={{__html: t("urbanLife")}} /></span><br />
                            <span
                                className="main-block__title-purple"> <span dangerouslySetInnerHTML={{__html: t("inTheCleanAir")}} /></span>
                        </div>
                        <div className="main-block__description">
                            <span dangerouslySetInnerHTML={{__html: t("cityAirBuildsContinuous")}} />
                        </div>
                    </div>
                    <div className="main-block__img">
                        <div
                            className="main-block__main-img-block main-block__main-img-block--mobile">
                            <div className="main-block__main-img-block--1"/>
                            <div className="main-block__main-img-block--2">
                                <img alt="" className="main-block__main-img"
                                     src={smartCityMain}/>
                                <div className="main-block__artefact"/>
                            </div>
                        </div>
                        <div
                            className="main-block__small-img-block main-block__small-img-block--mobile">
                            <div className="main-block__small-img-block--1">
                                <img alt=""
                                    className="main-block__small-img"
                                    src={smartCitySmall}/>
                            </div>
                            <div className="main-block__small-img-block--2"/>
                        </div>
                    </div>
                </div>
                <div
                    className="padding-top-50 wrapper_block wrapper_block--yellow wrapper_block__block wrapper_block__block--no-border title-and-img title-and-img--flex-start">
                    <div className="borders-block__col">
                        <div className="title-and-img__title">
                            <span dangerouslySetInnerHTML={{__html: t("smartCities")}} />
                        </div>
                        <div className="title-and-img__description">
                            <span dangerouslySetInnerHTML={{__html: t("airQualityDataService")}} />
                        </div>
                    </div>
                </div>

                <div className="wrapper_block wrapper_block__block wrapper_block--yellow wrapper_block__block--no-border borders-block">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span dangerouslySetInnerHTML={{__html: t("dataDrivenDecisions")}} />
                                </div>
                                <div className="borders-block__col--subtitle borders-block__col--point ">
                                    <span dangerouslySetInnerHTML={{__html: t("dueToContinuedExceedance")}} />
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal" />
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span dangerouslySetInnerHTML={{__html: t("localEngagementInspiration")}} />
                                </div>
                                <div className="borders-block__col--subtitle borders-block__col--point">
                                    <span dangerouslySetInnerHTML={{__html: t("provideCitizensWithHyperlocal")}} />
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal borders-block__hr--mobile" />
                        </div>
                        <div className="borders-block__hr" />
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">3
                                </div>
                                <div className="borders-block__col--title">
                                    <span dangerouslySetInnerHTML={{__html: t("airQualityManagementStrategy")}} />
                                </div>
                                <div className="borders-block__col--subtitle borders-block__col--point">
                                    <span dangerouslySetInnerHTML={{__html: t("findKeyPollutantsAndSources")}} />
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal" />
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">4</div>
                                <div
                                    className="borders-block__col--title">
                                    <span dangerouslySetInnerHTML={{__html: t("urbanPlanning")}} />
                                </div>
                                <div
                                    className="borders-block__col--subtitle borders-block__col--point">
                                    <span dangerouslySetInnerHTML={{__html: t("includeAirQualityFactorInto")}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="smart-city__end-block wrapper_block--padding-bottom wrapper_block wrapper_block__block wrapper_block__block--no-border">
                    <span className="smart-city__mail"
                          dangerouslySetInnerHTML={{__html: t("toGetADetailedInformation")}}/>
                </div>
            </div>
        </div>)
}

SmartCityComponent.propTypes = {}

SmartCityComponent.defaultProps = {}

export default SmartCityComponent
